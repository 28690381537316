@import "~assets/scss/variables.scss";

.root {
  width: 100%;
  min-width: 6em;

  h5, h6 {
    color: $primaryColor;
    margin-bottom: $minMargin * 2;
  }
  
  h6 {
    font: normal 20px/24px $defaultFontFamily;
  }

  input::placeholder, textarea::placeholder {
    color: $placeHolderTextColor;
  }
  
  input, textarea {
    width: 100%;
    color: $darkTextColor;
    border: 1px solid $borderColor;
    border-radius: 8px;
    &:focus {
      outline: none;
    }
  }
  .children {
    font-size: 21px;
    line-height: 34px;
  }

.input-40-width {
  max-width: 40%;
  margin: 1rem
}
.title-container{
  display: flex;
  justify-content: space-between;
  position: relative;
}

  .emoji-display-container{
    display: flex;
    z-index: 1;
    position: absolute;
    right: 8px;
    top: -35px;
  }
}

.cursor-pointer:hover {
  cursor: pointer;
}

.emoji-arrow{
  color: $placeHolderTextColor
}

.fixed-height {
  width: 100%;
  color: #4f586a;
  border: 1px solid #5f6872;
  border-radius: 8px;
  height: fit-content;
}

.full {
  flex-direction: row-reverse;
  display: flex;
  min-width: 100%;
  justify-content: space-between;
}

.emoji-board{
  position: absolute;
}

.sm {
  margin: $minMargin auto;
  h5 {
    font: bold 18px/22px $defaultFontFamily;
  }
  input, textarea {
    padding: 4px 6px;
    font-size: 16px;
  }
}

.lg {
  margin: $minMargin * 2 auto;
  h5 {
    font: bold 25px/24px $defaultFontFamily;
  }
  input, textarea {
    padding: 8px 12px;
    font-size: 21px;
  }
}

.error {
  font-size: 12px;
  color: $errorTextColor;
}

.box {
  background-color: $lightBackground;
}

:export {
  borderColor: $borderColor;
  primaryColor: $primaryColor;
  borderRadius: 8px;
}