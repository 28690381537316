$bold: 400;
$pageBackground: #dcdee6 0% 0% no-repeat padding-box;
$fullscreenModalBackground: #383f50bd;

$primaryColor: #2FBFFE;
$primaryHoverColor: rgb(52, 150, 192);

$darkTextColor: #4f586a;
$lightTextColor: #6e7c8b;
$placeHolderTextColor: #969fae;
$errorTextColor: #dc3545;
$errorTextHoverColor: #b32b39;

$disabledColor: #ced4da;

$smallerRadius: 5px;
$smallRadius: 10px;
$mediumRadius: 15px;
$bigRadius: 20px;
$xlRadius: 25px;

$darkBackground: #383f50;

$defaultBtnBorderRadius: 10px;
$defaultBtnHeight: 40px;

$defaultBtnFont: Bold 14px/15px;

$defaultFontFamily: Arial MT, Arial;

$defaultHeaderFont: Bold 48px/58px;
$defulaHeaderColor: #4f586a;

$navTopLinkColor: #eaebef;
$navTopLinkFont: Bold 16px/18px;

$mediumBackground: #dcdee6;
$tinyBackground: #f8f8f8;
$tinyDarkBackground: #dcdee6;
$disabledBackground: #e9ecef;
$lightBackground: white;
$mediumDarkBackground: #6e7c8b;

$borderColor: #5f6872;

/* FOOTER */
$footerTextColor: #dcdee6;

/* LOGO */
$logoRotate: rotate(10deg);

$minMargin: 6px;
$inputMinHeight: 30px;

$lgFontSize: 22px;
$mdFontSize: 18px;
$smFontSize: 14px;

/** Bootstrap variables **/

$theme-colors: (
  "primary": $primaryColor,
  "dark": $darkBackground,
);

// Breakpoints are defined as a map of (name: minimum width), order from small to large:
// We should add these in and use across the app for responsive design
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
/** Screen Sizing **/

$xsScreenSize: 0px;
$smScreenSize: 576px;
$mdScreenSize: 768px;
$lgScreenSize: 992px;
$xlScreenSize: 1200px;
$xxlScreenSize: 1380px;
