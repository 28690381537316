@import "~assets/scss/variables.scss";

.logo {
  img {
    height: 119px;
  }
  margin-bottom: 20px;
}

.card {
  border: 1px solid $tinyBackground;
  border-radius: $xlRadius;
  background-color: white !important;
  h4 {
    text-align: center;
  }
}

.card-body > * {
    margin: 30px 0;
}

.alert {
  color: $errorTextColor;
}

.modal {
  margin: auto;
}

.container-eighty-five {
  max-width: 85% !important;
}

.container-full-screen {
  min-width: 100% !important;
}