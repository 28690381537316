@import "~assets/scss/variables.scss";

.logo {
  height: 48px;
}

.org-logo{
  border: 1px solid $primaryColor;
  border-radius: 50px;
  height: 48px;
  width: 48px;
}

.gray {
  border: .1em solid gray;
  opacity: .33;
  padding: 0 0;
  margin: .1em 0;
}

.reg-symbol {
  color: $primaryColor;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  transform: none !important;
  margin-top: 5px;
}

.navbar-title {
  color: #eaebef;
  font-weight: bold;
}

.navbar-margin{
  margin-left: .5rem;
  margin-top: .55rem;
}

.logo-row {
  display: flex;
}

@media only screen and (max-width: 490px) {
  .navbar-title {
    display: flex;
    font-size: 0.87rem;
    align-items: center;
  }
}

@media only screen and (min-width: 950px) and (max-width: 1450px) {
  .navbar-title {
    display: flex;
    font-size: 0.87rem;
    align-items: center;
  }
}

@media only screen and (max-width: 360px) {
  .navbar-title {
    display: none;
  }
  .gray {
    display: none;
  }
  .org-logo {
    display: none;
  }
}