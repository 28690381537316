@import "~assets/scss/variables.scss";

.second-btn, .second-btn:hover, .second-btn:focus  {
  background-color: #ffffff;
  border-color: $primaryColor;
  color: $primaryColor !important;
  font: bold 18px/30px Arial MT, Arial;
  &:hover, &:focus {
    background-color: #ffffff !important;
    border-color: $primaryHoverColor !important;
    color: $primaryHoverColor !important;
    font: bold 18px/30px Arial MT, Arial;
  }
}

.primary-btn {
  background-color: $primaryColor;
  border-color: $primaryColor;
  color: #ffffff !important;
  font: bold 18px/30px Arial MT, Arial;
  &:hover, &:focus {
    background-color: $primaryHoverColor;
    border-color: $primaryHoverColor;
    color: #ffffff !important;
    font: bold 18px/30px Arial MT, Arial;
  }
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  overflow: scroll;
}

.org-option {
  width: 100%;
}