@import "~assets/scss/variables.scss";

.logo {
  img {
    height: 119px;
  }
  margin-bottom: 20px;
}

.card {
  border: 1px solid $tinyBackground;
  border-radius: $xlRadius;
  h4, h5 {
    text-align: center;
  }
}

.alert {
  color: $errorTextColor;
}

.modal {
  margin: auto;
}

.content {
  text-align: center;
}
