@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";

.title-container {
  display: flex;
  flex-direction: column;
}

.disable-type-button {
  opacity: 0.6;
  cursor: not-allowed;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap-reverse;

  h1,
  h4 {
    color: $darkTextColor;
  }

  h1 {
    font: bold 48px/58px $defaultFontFamily;
  }
}

.btns-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  button {
    margin: $minMargin auto;
    height: 36px;
    text-transform: uppercase;
    font: bold 14px/15px $defaultFontFamily;
    border-radius: $smallRadius;
  }

  button:last-child {
    margin-left: $minMargin * 2;
  }

  @media (max-width: 430px) {
    button:last-child {
      margin: auto;
    }
  }
}

.card {
  margin-top: $minMargin * 2;
}

.card-wrapper {
  margin: $minMargin * 4;
  background-color: $lightBackground;
  display: flex;
}

.subHeader-row{
  margin: 0px;
}

.subHeader-row{
  margin: 0px;
  align-items: flex-end;
  justify-content: space-between;
}

.padding-none{
  padding: 0;
}

.p3-no-top{
  padding: 0 3rem 3rem 3rem;
}

.no-left-margin-button{
  margin: .25rem .25rem .25rem 0 !important;
}

.no-left-margin-button-disabled{
  margin: .25rem .25rem .25rem 0 !important;
  opacity: 0.6;
  cursor: not-allowed;
}

.fifty-percent-col {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.seventy-percent-col {
  display: flex;
  flex-direction: column;
  max-width: 70%;
}

.pie-col {
  margin-top: .5em;
  display: flex;
  flex-direction: column;
}
.adoption-overview-card {
  width: 100%; /* Ensures the card takes full width of its container */
  max-width: 100%; /* Prevent it from expanding beyond its container */
  background-color: #fff;  /* Card background color */
  border-radius: 8px;
  //box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;  /* Add margin to create space between elements */
}

.header-title {
  text-align: center;
  margin-top: .5em;
}

@media (max-width: $lgScreenSize) {
  .pie-col {
    min-width: 100%;
    max-width: 100%;
  }
}

@media (min-width: $lgScreenSize) {
  .pie-col {
    max-width: 35%;
  }
}

@media (max-width: $xlScreenSize) {
  .seventy-percent-col {
    max-width: 100%;
  }
}

.centered-col {
  justify-content: space-around
}

@media (max-width: $xxlScreenSize) {
  .subHeader-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.overflow-col {
  overflow-x: scroll;
}