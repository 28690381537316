@import "./variables.scss";

.root {
  margin: 2rem;
}

@media only screen and (max-width: 400px) {
  .root {
    margin: 20px;
  }
}

.btn-wrapper,
.horizontal-btn-wrapper {
  margin-top: $minMargin * 4 !important;
  margin: auto;
}

.horizontal-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin: auto $minMargin * 2;
  width: 100%;
}

.section {
  margin: 0 24px 24px 24px;
}

.submit-wrapper {
  margin: 0;
  justify-content: center;
  button {
    width: 326px;
  }
}

.profile-pic {
  width: 188px;
  height: 188px;
  border: 1px solid $primaryColor;
  border-radius: 50%;
}

@media only screen and (min-width: 1024px) {
  .submit-wrapper {
    margin: $minMargin;
  }
}
