@import "~assets/scss/variables.scss";

.pie-container {
  border: .5em solid $mediumDarkBackground;
  border-radius: 2em;
  box-sizing: border-box;
  text-align: center;
}

.pie-title{
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  justify-content: center;
}