@import "~assets/scss/variables.scss";

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin-bottom: $minMargin;
  label {
    color: $defulaHeaderColor;
    font: bold 22px/25px $defaultFontFamily;
    color: $primaryColor;
  }
}