@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";
.row-header {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem;
    justify-content: space-between;
}

.disabled-button {
  opacity: 0.6;
  cursor: not-allowed;
}
@media (max-width: $lgScreenSize) {
  .modal-dialog {
    max-width: 800px !important;
    margin: 1.75rem auto;
  }
}
