@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";

.custom-card {
  background-color: $mediumDarkBackground;
  padding: $minMargin * 4 $minMargin * 6 !important;
  
  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: $minMargin * 2;
  }

  h6, span {
    color: white;
    letter-spacing: 0px;
  }

  h6 { 
    font: Bold 20px/24px $defaultFontFamily;
  }
  
  span {
    font: normal 20px/24px $defaultFontFamily;
  }
}

.submit-wrapper {
  button:last-child {
    margin-left: $minMargin * 4;
  }
}

h5, h6 {
  color: $primaryColor;
  
}

.csvContainer {
  margin-top: 1rem;
  overflow-x: auto;
}

.csvTable {
  width: 100%;
  border-collapse: collapse;
}

.alert {
  font-family: $defaultFontFamily;
  color: red;
  font-size: 1rem;
}

.scrollableTableContainer {
  position: relative; /* Ensure the container has a positioning context */
  overflow: visible;  /* Allow dropdowns to overflow */
}

.csvTable {
  z-index: 1; /* Ensure table stays beneath the dropdown */
}

.csvTable thead th {
  background-color: #f8f9fa; /* Light grey background */
  position: sticky;
  top: 0;
  z-index: 2;
}

.csvTable th,
.csvTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.dropdownContainer {
  display: flex;
  flex-direction: column; /* Stack dropdowns vertically */
  gap: 1rem; /* Add spacing between dropdowns */
}

.dropdownWrapper {
  width: 100%; /* Allow dropdowns to take full width */
}

.navbar-links {
  a {
    color: black; /* Default color for inactive tabs */
    font-size: 2rem; /* H2 size equivalent */
    font-weight: 600; /* Bold */
    text-decoration: none; /* Optional */
    &:hover {
      color: black; /* Ensure hover stays black for inactive tabs */
    }
  }

  .active-link {
    color: blue !important; /* Active tab will have blue color */
  }
}
.navbar-links .active-link {
  color: blue !important; /* Active tab will have blue color */
  border-bottom: 2px solid blue; /* Add a border for the active tab */
}


.scrollableTableContainer {
  max-height: 200px; /* Set a fixed height for vertical scrolling */
  overflow-y: auto;  /* Enable vertical scrolling */
  overflow-x: auto;  /* Enable horizontal scrolling */
  border: 1px solid #ddd; /* Optional: Add a border for better visibility */
  margin-top: 10px; /* Optional: Add some spacing above */
}




@media only screen and (max-width: 767px) {
  .submit-wrapper {
    margin-top: $minMargin * 4;
    button:last-child {
      margin-left: 0;
      margin-top: $minMargin * 2;
    }
  }
}

@media only screen and (max-width: 465px) {
  .section {
    margin: 24px 0
  }
}