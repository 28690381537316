@import "~assets/scss/variables.scss";

/* Customize the label (the container) */
.root {
  h5, h6 {
    padding-top: 10px;
    color: $primaryColor;
  }

  h6 {
    font: normal 20px/24px $defaultFontFamily;
  }
}
.label {
  margin: 0 .5em 0 .5em;
  padding-right: 10px;
  font-size: $mdFontSize;;
  margin-left: 1em;
}
.input[type='radio'] {
  transform: scale(2);
  text-align:  center;
}
.radioInput {
  margin: .5em .25em .5em .25em;
}

.sm {
  //margin: $minMargin auto;
  h5 {
    font: bold 18px/22px $defaultFontFamily;
  }
}

.lg {
  //margin: $minMargin * 2 auto;
  h5 {
    font: bold 25px/24px $defaultFontFamily;
  }
}

.error {
  font-size: 12px;
  color: $errorTextColor;
}

:export {
  borderColor: $borderColor;
  primaryColor: $primaryColor;
  borderRadius: 8px;
}
