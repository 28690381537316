@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";

.verification-wrong-message{
  color: red !important;
  font-size: $mdFontSize;
  text-align: center;
}

.phone-input-verification-text-box{
  margin-bottom: 1rem;
}

.paragraph-title-text{
  margin: 0 2.5rem 2rem 2.5rem;
}