@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";

.edit {
   width: 2em;
   height: 2em;
   margin-right: .25em;
   float: right;
}
.cards {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   min-width: 100%;
}

.container{
	display: flex;
	flex-direction: row;
	width: 100%;
}

.card a {
	color: black;
	text-decoration: none;
}

.card a:hover {
	box-shadow: 3px 3px 8px hsl(0, 0%, 80%);
}

.card-content, .insight-card-content {
	padding: .5em;
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.card-content h2 {
	margin-top: 0;
	margin-bottom: .5em;
	font-weight: bold;
	color: $primaryColor;
	text-align: center;
  	font-weight: bold;
}

.card-content p {
	font-size: 80%;
}

.card-detail {
	display: flex;
	flex-direction: row;
	min-width: 100%;
	overflow-wrap:break-word;
	padding: .25em 0 .25em 0;
}

.card-detail-header {
	display: flex;
	flex-direction: row;
	color: $primaryColor;
	font-weight: bold;
	min-width: 20%;
}

.card-detail-text {
	display: flex;
	flex-direction: column;
	max-width: 60%;
	padding-left: 1em;
}

.card-header {
	overflow-wrap:break-word;
}

.card-body {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-wrap: wrap;
	padding-bottom: 2em;
	justify-content: space-between;
	border-bottom: 1px solid $mediumDarkBackground;
	border-top: 1px solid $mediumDarkBackground;
	min-height: 80%;
}

.card-body-insight {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-wrap: wrap;
	padding-bottom: 2em;
	justify-content: space-between;
	border-top: 1px solid $mediumDarkBackground;
	min-height: 80%;
	overflow-wrap: anywhere
}

.card-body-nudge-today{
	border-top: 1px solid $mediumDarkBackground;
}

.card-footer {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-self: flex-end;
	max-height: 3em;
}

.card-footer-creator {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 1em;
}

.card {
	background: $mediumBackground;
	margin: .5em;
	font-size: $mdFontSize;
	border-radius: 1em;
	min-width: 45%;
	max-width: 45%;
	border: .5em solid $mediumDarkBackground;
}

.card-insight {
	background: whitesmoke;
	margin: .5em;
	font-size: $mdFontSize;
	border-radius: 1em;
	min-width: 45%;
	max-width: 45%;
	border: .5em solid $mediumDarkBackground;
	cursor: pointer;
}

.card-insight-today {
  @extend .card-insight;
  cursor: auto;
}

.card-header {
  overflow-wrap:break-word
}

@media (max-width: 768px) {
	.card {
		min-width: 100%;
	}
}


@media (max-width: 935px) {
	.card-insight {
		min-width: 95%;
	}
}
@media (min-width: 1200px) {
	.card {
		max-width: 30%;
		min-width: 30%;
	}
	card-insight {
		min-width: 30%;
	}
}

@media (max-width: 1680px) {
	.card-detail-header {
		min-width: 25%;
	}
}

@media (max-width: 1420px) {
	.card-detail-header {
		min-width: 30%;
	}
}

@media (max-width: 900px) {
	.card-detail-header {
		min-width: 40%;
	}
}

@media (max-width: 768px) {
	.card-detail-header {
		min-width: 20%;
	}
}

.insight-icon {
	width: .75em;
}

.card-header-title {
	max-width: 90%;
}

.insight-card-header-title {
	 @extend .card-header-title;
	padding-left: .25em;
}
.card-header-arrow {
	max-width: 10%;
}
.bold-label {
	font-weight: bold;
	font-size: 100%;
	margin-right: .25em
}

.label-value-container {
	display: flex;
}

.bold-click-text {
	font-weight: bold;
}