@import "~assets/scss/variables.scss";

.tooltip-icon-alt {
  position: absolute;
  top: 0;
  right: 0;
}

.tooltip-icon-cp {
  position: absolute;
    top: 60px;
    right: 40px;
    left: 355px;
}


.emoji-display-container{
    display: flex;
    z-index: 2;
  }

.cursor-pointer:hover {
  cursor: pointer;
}

.tool-tip-container{
  width: 5%;
  position: absolute;
  right: 10%;
  top: calc(100% - 3em);
  float: right;
  cursor: pointer;
}

.tool-tip-container-nudge{
  float: right;
  cursor: pointer;
}