.icon {
  height: 40px;
  width: 40px;
}

.button {
  background: transparent;
  outline: none;
  border: none;
}

.emoji-picker-react{
  margin: 0 .5rem 0 .5rem;
}

.emoji-picker{
  width: 420px !important;
}