@import "~assets/scss/variables.scss";

.card-adoption-overview {
	background: whitesmoke;
	margin: .5em;
	font-size: $mdFontSize;
	border-radius: 1em;
	border: .5em solid $mediumDarkBackground;
	margin-left: 4px;
	width: 100%
}

.card-body-adoption-overview {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-wrap: wrap;
	padding-bottom: 2em;
	justify-content: space-between;
	min-height: 80%;
}

.card-adoption-overview-header {
  overflow-wrap:break-word;
  text-align: center;
}