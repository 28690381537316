@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";

.preview-style{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1em 0;
  h6 {
    font-size: smaller;
    color: $lightTextColor;
  }
}

.disabled-button {
  opacity: 0.6;
  cursor: not-allowed;
}

.text-container {
  position: relative;
  text-align: center
}


.preview-header{
  display: inline-block;
  font: bold 36px/43px Arial MT, Arial;
  color: $darkTextColor;
}

.preview-sub-header{
  color: $primaryColor;
  text-align: center;
  font: normal 20px/24px Arial MT, Arial;
  padding: 0em 1.5em
}

.preview-footer {
  text-align: center;
  font: normal 20px/24px Arial MT, Arial;
  color: $primaryColor;
}

.score-container {
  border: .2em solid $mediumBackground;
  border-radius: 1em;
  padding: 1%;
  display: flex;
  flex-direction: column;
  .notification-title {
    padding-left: 7px;
  }
  .msg-score {
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
  }
  .exceptional-msg {
    @extend .msg-score;
    color: #009E10
  }
  .average-msg {
    @extend .msg-score;
    color: $primaryColor;
  }
  .low-msg {
    @extend .msg-score;
    color: red
  }
  .app-title{
    text-transform: uppercase;
    font-size: 8pt;
    top: 10px;
    position: absolute;
    left: 35px;
  }
  .notification-header {
    img {
      width: 22px;
      margin: 8px;
      margin-bottom: 0;
    }
    .app-noti-time {
      position: absolute;
      top: 10px;
      right: 10px;
      text-transform: none;
      font-size: 8pt;
    }
  }
}