@import "~assets/scss/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: $minMargin * 2 $minMargin;
  min-height: 60px;
  justify-content: end;

  span {
    font-weight: bold;
    text-align: center;
    // font-size: $mdFontSize;
    width: 30%
  }

  .actual-page {
    width: 75%
  }
}

.buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 30px;
    height: 30px;
    color: black;
    border: 1px solid rgb(219, 219, 219) !important;
    font-weight: bolder;
    font-size: 18px;
    padding: 0;
  }
  .page {
    width: 30px;
    height: 30px;
    border: 1px solid rgb(219, 219, 219);
    background-color: $lightBackground;
    margin: auto $minMargin;
    border-radius: $smallerRadius;
    padding-top: 2px;
  }
}