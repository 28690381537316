@import "~assets/scss/variables.scss";

.root {
  background-color: $tinyBackground;
  border: 1px solid $tinyBackground;
  border-radius: $mediumRadius;
  width: 100%;
}

.header {
  width: 100%;
}

h3 {
  font: bold 36px/43px $defaultFontFamily;
  letter-spacing: 0px;
  color: $darkTextColor;
  margin-bottom: $minMargin * 3;
}

.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}