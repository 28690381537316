.root {
  height: calc(100vh - 40px);
  display: flex;
  align-items: center;
}

.spinner {
  width: 5rem;
  height: 5rem;
  margin: 64px auto;
}
