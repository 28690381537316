@import "~assets/scss/variables.scss";

.root {
  div[class*="CustomCard_root"] {
    background: $fullscreenModalBackground;
  }
}

.custom-card {  
  h2 {
    color: $errorTextColor
  }
}