.root {
  display: flex;
}

.badge {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 6px;
  border: 1px solid grey;
  background-color: black;
}

.purple {
  background-color: #AD30E5;
}

.purple-2 {
  background-color: #8B2FEE;
}

.orange {
  background-color: #EE8B2F;
}

.green {
  background-color: #62D90A;
}

.green-2 {
  background-color: #84D53F;
}

.yellow{
  background-color: #ffc107;
}

.blue {
  background-color: #2996E5;
}

.blue-2 {
  background-color: #4d93e0;
}