@import "~assets/scss/variables.scss";
@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";

.time-based-analytics-container {
  position: relative;
  text-align: center
}

.time-based-analytics-row {
  display: flex;
  justify-content: space-between;
}