@import "./variables.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap";

body {
  font-family: $defaultFontFamily;
}

h1,
h2,
h3 {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5 {
  color: $darkTextColor;
}

.btn {
  border-radius: $smallRadius;
}

.btn-lg {
  min-width: 140px;
}

.btn-xl {
  padding: 1rem 1.75rem;
  font-size: 1.75rem;
  line-height: 1.5;
  font-weight: bold;
}

.card {
  border-radius: $xlRadius;
  background-color: $tinyBackground;
}

.form-control {
  border-radius: $smallRadius;
}

.bg-md {
  background-color: $mediumBackground !important;
}

.bg-md-dark {
  background-color: $mediumDarkBackground !important;
}

.bg-tiny {
  background-color: $tinyBackground !important;
}

.bg-tiny-dark {
  background-color: $tinyDarkBackground !important;
}
