@import "./assets/scss/variables.scss";
@import "./assets/scss/bootstrap.scss";

.btn-danger {
  background-color: $errorTextColor !important;
}

.btn-danger:hover {
  background-color: $errorTextHoverColor !important;
}

.btn-info,
.btn-info:disabled {
  background-color: $primaryColor;
  border-color: $primaryColor;
  border-radius: $defaultBtnBorderRadius;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: $primaryHoverColor !important;
  border-color: $primaryHoverColor !important;
  text-decoration: none;
}

.btn-danger,
.btn-danger:disabled {
  border-radius: $defaultBtnBorderRadius;
}

body {
  background: $pageBackground;
}

#container {
  border-top-left-radius: $bigRadius;
  border-top-right-radius: $bigRadius;
  border-bottom-left-radius: $bigRadius;
  border-bottom-right-radius: $bigRadius;
  background-color: white;
}

.centered {
  margin: 20px;
}

/* NAVBAR */
.navbar-nav {
  background-color: $darkBackground;

  .nav-link.nav-toplink {
    display: flex;
    align-items: center;
    font: $navTopLinkFont $defaultFontFamily;
    color: $navTopLinkColor;
    border-top: 11px solid rgba(255, 0, 0, 0);
    border-bottom: 11px solid rgba(255, 0, 0, 0);
  }

  .nav-link.nav-toplink:hover {
    color: darken($navTopLinkColor, 20%);
  }

  .nav-link.nav-toplink.active {
    border-top: 11px solid $primaryColor;
    border-bottom: 11px solid $primaryColor;
  }
}

.nav-toplink .dropdown-toggle.nav-link {
  height: 100%;
  display: flex;
  align-items: center;

  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;

  font: $navTopLinkFont $defaultFontFamily;
  color: $navTopLinkColor;
}

.nav-toplink.active .dropdown-toggle.nav-link {
  border-top: 11px solid $primaryColor;
  border-bottom: 11px solid $primaryColor;
}

.nav-toplink .dropdown-toggle.nav-item:hover {
  border-top: 11px solid $darkBackground;
}

/* TABLES */
.card {
  border-radius: $bigRadius;
  background: $mediumBackground;
}

.card-title {
  border-radius: $mediumRadius;
  background: $lightBackground;
  padding: 0.5em;
}

.card-text {
  border-radius: $mediumRadius;
  background: $lightBackground;
  padding: 0.5em;
}

table {
  background: $lightBackground;
}

img {
  object-fit: cover;
}
