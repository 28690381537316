@import "~assets/scss/variables.scss";

@mixin label {
  color: $defulaHeaderColor;
  font-weight: bold;
  margin: 0;
}

.filter-label {
  color: $defulaHeaderColor;
  font-weight: bold;
  margin: 0;
}

.filters-container {
  width: 97%;
  margin: auto;
  padding-top: $minMargin * 2;

  .filter {
    &:first-child {
      margin-left: 0;
    }
  }
}

.col-filters {
  display: flex;
  flex-direction: column;
  max-width: 60%
}
@media (max-width: $mdScreenSize){
   .col-filters {
      min-width: 100%;
      //max-width: 50%;
   }
}
.category-filter{
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  padding-top: $minMargin;
  min-width: 28%;
  //max-width: 33%;
};

@media (max-width: $mdScreenSize){
   .category-filter {
      min-width: 50%;
   }
}

.search-pagination-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  max-width: 40%;
}

@media (max-width: $mdScreenSize){
   .search-pagination-container {
      min-width: 100%;
   }
}

@media (max-width: $lgScreenSize){
   .bulk-action-filter {
      min-width:75% !important;
   }
}

.filter, .category-filter {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  padding: 0 .5em 0 0;
  min-width: 30%;
  margin-bottom: .5em;

  .label {
    @include label;
  }

  .dropdown-wrapper {
    display: flex;
    flex-direction: row;
    gap: .5em;
    width: 100%;
  }

  .dropdown {
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px $minMargin * 2;
      background-color: $lightBackground;
      border-color: $primaryColor;
      color: $defulaHeaderColor;
      font-weight: bold;
      border-radius: $smallerRadius;
      margin: .25em 0px;

      &:hover,
      &:active,
      &:focus {
        background-color: white !important;
        color: $defulaHeaderColor !important;
      }
    }
  }

    .categoryDropdown {
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px $minMargin * 2;
      background-color: $lightBackground;
      border-color: $primaryColor;
      color: $defulaHeaderColor;
      font-weight: bold;
      border-radius: $smallerRadius;
      max-width: 50%;

      &:hover,
      &:active,
      &:focus {
        background-color: white !important;
        color: $defulaHeaderColor !important;
      }
    }
  }

  input {
    height: $inputMinHeight;
    padding: 2px $minMargin;
  }
}

@media (max-width: $lgScreenSize){
   .filter {
      min-width: 50%;
   }
}

@media (max-width: $lgScreenSize){
   .category-filter {
      min-width: 50%;
   }
}

.clear-filter {
  margin-left: auto;
}

.select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25em;

  label {
    flex: 1;
    margin-right: $minMargin;
    margin-bottom: 0;
  }

  .dropdown {
    width: 100%;
  }
}

.searchbar-container {
  display: flex;
  min-width: 70%;
  align-items: center;
}

.searchbar {
  border-color: black;
  margin-right: 1em;
  max-width: 60%;
  min-width: 50%;
}

.cells-centered{
  text-align: center;
}

.table {
  width: 97%;
  table-layout: fixed;
  margin: 0 auto 6em;

  td {
    text-overflow: ellipsis;
    min-width: 140px;
  }

  .header {
    background: $mediumDarkBackground;
    color: white;
  }
}

.table-td--Status {
  width: 8em;
}

.table-td--Type {
  width: 6em;
}

.table-td--Description {
  width: 20em;
}

.table-td--Members {
  width: 6em;
}

.table-td--Select {
  width: 75px;
}

.table-td--Actions {
  width: 200px;
}

.table-td--Category {
  width: 10em;
}

.table-td--Name {
  width: 10em;
}

.table-td--Name-Partner {
  width: 12em;
}

.table-td--Created {
  width: 6.7em;
}

.table-td--Message {
  width: 13em;
}

.table-td--Delivery-Details {
  width: 18em;
}

.table-td--In-Use {
  width: 5em;
}

.table-td--Refreshed {
  width: 8em;
}

.table-td--Title {
  width: 10em;
}

.table-td--Delivery {
  width: 5em;
}

.table-td--Details {
  width: 20em;
}

.table-td--Description {
  width: 11em;
}

.table-td--Type-Partner {
  width: 8em;
}

.table-td-App-Users {
  width: 8em;
}

.table-td--Tier {
  width: 6em;
}

.table-td--Geo-Add-on{
  width: 4em;
}

.table-td--Org-Id{
  width: 5em;
}

.table-td--APNS-Configured{
  width: 6em;
}

.table-td--APNS-Mode{
  width: 10em;
}

.table-td--Launch-Date{
  width: 8em;
}

@media (min-width: 1440px) {

  .table-td--Status {
    width: 8em;
  }

  .table-td--Type {
    width: 6em;
  }
}

.overflowTable {
  overflow-x: scroll;
}
  .clear-btn {
    min-width: 8em;
  }

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: $lgScreenSize) {
      flex-wrap: wrap;
    }

  button {
    @media (max-width: $lgScreenSize) {
      margin: .5em 0px;
    }
    text-transform: uppercase;
    font-weight: bold;
    height: 30px;
  }
}

.pagination-wrapper {
  display: inline-block;
  justify-content: flex-end;
  padding: 0 1em;
}

.pag-top-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.table-view{
  overflow-x: scroll;
}

.table-footer-row{
  justify-content: flex-end;
}