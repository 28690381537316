@import "~assets/scss/variables.scss";

@mixin navbar-font {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 12pt;
  font-weight: 300;
}

.root {
  a {
    text-decoration: none;
    color: $lightTextColor;
    &:hover {
      color: $darkTextColor;
    }
  }
  border-top-left-radius: $bigRadius;
  border-top-right-radius: $bigRadius;
  background-color: $darkBackground;
  height: 142px;
  margin-bottom: 2em;
  padding: 0 30px;
  z-index: 1;
}

.nav-brand-header {
  a {
    color: $primaryColor;
    &:hover {
      color: $primaryColor;
    }
  }
  span {
    transform: $logoRotate;
    display: inline-block;
  }
  @include navbar-font;
}

.public-header {
  background: $darkBackground;
  border-top-right-radius: $bigRadius;
  border-top-left-radius: $bigRadius;
  font-size: 24pt;

  span {
    margin-top: -10px;
  }

  padding: 10px 30px;

  @include navbar-font;
}

.navbar-collapse {
  height: 100%;

  .navbar-nav {
    border-radius: 10px;
  }

  @media (max-width: 989px) {
    .navbar-nav {
      padding: 10px;
    }
  }

  @media (min-width: 990px) {
    .navbar-nav {
      height: 100%;
    }
  }
}

// AVATAR

.navbar-avatar {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.avatar-img {
  border: 1px solid $primaryColor;
  border-radius: 50px;
  height: 60px;
  width: 60px;
}
