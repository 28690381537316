@import "~assets/scss/variables.scss";

.root {
  display: flex;
  justify-content: center;
  min-height: 46px;
  margin-bottom: $minMargin;
  h4, span {
    font: bold 18px/22px $defaultFontFamily;
  }
  h4 {
    flex: 3;
    color: $primaryColor;
    margin: .5em 0 .5em 0;
    font-weight: bold;
    font-size: $lgFontSize;
    text-align: left;
  }
  span {
    color: $lightTextColor;
  }

}

.children {
  flex: 7;
}