@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";

.horizontal, .week-days-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.week-days-wrapper {
  align-items: center;
  
  div {
    margin-top: 0;
  }

  .label {
    margin-top: $minMargin + 4px;
    min-width: 150px;
  }
}

.label {
  color: $primaryColor;
  font: bold 22px/23px $defaultFontFamily;
  margin-left: $minMargin * 2;
  min-width: 150px;
}

.alert {
  font-family: $defaultFontFamily;
  color: red;
  font-size: 1rem;
}

.time-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h6 {
    color: $darkTextColor;
    margin: 0;
    margin-right: $minMargin * 2;
    font: bold 22px/25px $defaultFontFamily;
  }
}

.floating {
  display: none;
  margin: $minMargin * 2;
  justify-content: center;
  min-width: 428px;
  max-height: 90%;

  .custom-card {
    display: block;
    padding: 10px;
    width: 428px;
    bottom: 150px;
  }
}

.disable-type-button {
  opacity: 0.6;
  cursor: not-allowed;
}

.refresh-button {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: white;
}

@media only screen and (min-width: 1200px) {
  .custom-card {
    position: fixed;
  }
}

@media only screen and (min-width: 525px) {
  .floating {
    display: flex;
  }
}

@media only screen and (min-width: 768px) {
  .pl {
    padding-left: 30px !important
  }
}