@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";

.role-input {
  color: $darkTextColor;
  margin: 0;
  font-size: 16px !important;
}

.company {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  button {
    max-width: 190px;
  }
}

.my-account-column-flex {
  display: flex;
}

.my-account-col-flex {
  display: flex;
  flex-direction: column;
}

.password-rules-font {
  font-size: .95em
}

.profile-picture-container {
  cursor: pointer;
  margin-bottom: 18px;
  span {
    margin-top: 6px;
    text-decoration: underline;
    color: $primaryColor;
  }
}

.profile-picture-block {
  margin-bottom: 10px;
}

.profile-picture-anchor {
  margin-bottom: 5px;
}

.invite-user-container {
  span {
    font-weight: normal;
     margin: .5em 0 .5em 0;
  }
  .left-margin-field {
    margin: 1em 10%
  }
}

.invite-user-card {
  background-color:#f8f8f8;
  flex-grow: 1;
}

.manage-user-card {
  background-color:#f8f8f8;
  margin: 0em 0em 2em 0em;
}

.small-header{
  text-align: left !important;
  font-weight: bold;
  margin: .5em 0;
}

.invite-user-checkbox-bold{
  font-size: $mdFontSize;
  margin: .5em 0 .5em 0;
  align-content: center;
  align-items: center;
}
