@import "~assets/scss/variables.scss";

button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: $mediumRadius;
  text-decoration: none;
  outline: none !important;
  -webkit-appearance: none;
}

.sm {
  width: 160px;
  height: 47px;
  font: bold 16px/20px $defaultFontFamily;
  margin: auto;
}

.lg {
  width: 100%;
  padding: 34px 55px;
  height: 94px;
  font: bold 30px/24px $defaultFontFamily;
}

button:disabled, button:disabled:focus, button:disabled:hover {
  background-color: $disabledBackground;
  color: $disabledColor;
  border-color: $disabledColor;
}

/* PRIMARY COLOR */

.primary {
  background-color: $lightBackground;
  color: $darkTextColor;
  border: 1px solid $primaryColor;
}

.primary.contained {
  background-color: $primaryColor;
  color: $lightBackground;

  &:hover, &:focus {
    background-color: $primaryHoverColor;
    border-color: $primaryHoverColor;
  }
}

.primary.outlined {
  background-color: $lightBackground;
  color: $primaryColor;

  &:hover, &:focus {
    color: $primaryHoverColor;
    border-color: $primaryHoverColor;
  }
}

/* ERROR COLOR */

.error {
  background-color: $errorTextColor;
  color: $lightBackground;
  border: 1px solid $errorTextColor;
}

.error.contained {
  &:hover, &:focus {
    background-color: $errorTextHoverColor;
    border: 1px solid $errorTextHoverColor;
  }
}

.error.outlined {
  background-color: $lightBackground;
  color: $errorTextColor;

  &:hover, &:focus {
    color: $errorTextHoverColor;
    border-color: $errorTextHoverColor;
  }
}