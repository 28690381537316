@import '~assets/scss/variables.scss';
@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";

.section {
  margin: 0 0 24px 0;
}

.third {
  width: 33%;
  display: flex;
  flex-direction: row;
  padding: 0px
}

.fourth {
  max-width: 25%;
  display: flex;
  padding: 0px;
  margin: 0 .5em 0 0;
}

.activate {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: flex-end;
  align-content: center;
  padding: 0
}

.horizontal, .week-days-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.week-days-wrapper {
  align-items: center;

  div {
    margin-top: 0;
  }

  .label {
    margin-top: $minMargin + 4px;
    min-width: 150px;
  }
}

.label {
  color: $primaryColor;
  font: bold 22px/23px $defaultFontFamily;
  margin-left: $minMargin * 2;
  min-width: 150px;
}

.time-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h6 {
    color: $darkTextColor;
    margin: 0;
    margin-right: $minMargin * 2;
    font: bold 22px/25px $defaultFontFamily;
  }
}

.floating {
  display: none;
  margin: $minMargin * 2;
  justify-content: center;
  min-width: 428px;
  max-height: 90%;

  .custom-card {
    display: block;
    padding: 10px;
    width: 428px;
    bottom: 150px;
  }
}

.disable-type-button {
  opacity: 0.6;
  cursor: not-allowed;
}

.refresh-button {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: white;
}

@media only screen and (min-width: 1200px) {
  .custom-card {
    position: fixed;
  }
}

@media only screen and (min-width: 525px) {
  .floating {
    display: flex;
  }
}

@media only screen and (min-width: 768px) {
  .pl {
    padding-left: 30px !important
  }
}

.alert {
  font-family: $defaultFontFamily;
  color: red;
  font-size: 1rem;
}

.button {
  width: 74px;
  height: 30px;
  background-color: $lightBackground;
  padding: 0px;
  color: $primaryColor;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  border-color: $primaryColor;
  border-radius: $smallerRadius;
  &:hover {
    background-color: $primaryColor;
    color: white;
    border-color: $primaryColor;
  }
}
