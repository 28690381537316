@import "~assets/scss/variables.scss";

.preview-style {
  text-align: center;
  width: 408px;
  font-size: 0.80rem;

  h6 {
    font-size: smaller;
    color: $lightTextColor;
  }
}

.notification-div {
  min-height: 110px;
  max-height: 135px;
  border: 2px solid black;
  border-radius: 7px;
  margin: 0.5rem 1.985rem;
  background-color: #f0f0f0;
  text-align: left;
  position: relative;
  bottom: 143px;
  left: 0;
  width: 340px;
  opacity: 0.7;
  overflow: hidden;
  padding: 12px;
  display: flex;
  align-items: center; /* Align items to the center vertically */
  gap: 8px; /* Add some space between the logo and content */
  height: auto; /* Allow height to grow */
}

.notification-wrapper {
  flex-grow: 1;
  text-overflow: ellipsis;
}

.custom-section {
  padding-left: 4px;
  text-align: left;
  text-overflow: ellipsis;
  flex-grow: 1; /* Allow custom-section to take available space */
}

.notification-msg {
  padding-right: 8.1px;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;
}

.notification-title {
  padding-left: 2px;
  padding-top: 30px;
  text-overflow: ellipsis;
  max-height: 4em;
}

.app-title {
  text-transform: uppercase;
  font-size: 8pt;
  top: 10px;
  position: absolute;
  left: 35px;
}

.notification-header img {
  width: 22px;
  margin: 8px;
  margin-bottom: 0;
}

.app-noti-time {
  position: absolute;
  top: 10px;
  right: 10px;
  text-transform: none;
  font-size: 8pt;
}
