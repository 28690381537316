@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";

.logo {
  width: 20px;
  height: 24px;
  margin-right: 3px;
}
.trendIcon, .trendIconModal {
  width: 32px;
  height: 32px;
  margin-right: 3px;
}
.trendUpALot {
  color: #62d90a;
  font-size: 1.5rem;
  vertical-align: middle;
}
.trendUp, .trendUpModal {
  color: navy;
  font-size: 1.5rem;
  vertical-align: middle;
}
.trendDown, .trendDownModal {
  color: navy;
  font-size: 1.5rem;
  vertical-align: middle;
}
.trendDisabled, .trendDisabledModal {
  color: gray;
  font-size: 1.5rem;
  vertical-align: middle;
}
.trendDownUpALot {
  color: #f00;
  font-size: 1.5rem;
  vertical-align: middle;
}
.graph {
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 10px;
  max-height: 500px;
}
.analyticsTableRow{
  line-height: 1;
}
.colInfo, .colInfoModal {
    color: #4f586a;
    font-size: 1rem;
    padding-top: 3px;
    text-align: center;
    vertical-align: middle;
  }
.rowInfo, .rowInfoModal {
  color: #4f586a;
    font-size: 1rem;
    padding-top: 3px;
    text-align: center;
    position: relative;
  }
  .rowInfoDisabled, .rowInfoDisabledModal {
    color: gray;
      font-size: 1rem;
      padding-top: 3px;
      text-align: center;
      position: relative;
    }
@media screen and (min-width: 320px) {
  .colInfo {
    font-size: calc(12px + 6 * ((100vw - 320px) / 680));
    padding-top: 3px;
  }
  .colInfoModal {
    font-size: calc(8px + 6 * ((100vw - 320px) / 680));
    padding-top: 3px;
  }
  .rowInfo, .rowInfoDisabled {
    font-size: calc(8px + 6 * ((100vw - 320px) / 680));
  }
  .rowInfoModal, .rowInfoDisabledModal {
    font-size: calc(6px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
    .colInfo {
    font-size: calc(16px + 6 * ((100vw - 320px) / 1000));
    padding-top: 3px;
  }
  .colInfoModal {
    font-size: calc(12px + 6 * ((100vw - 320px) / 1000));
    padding-top: 3px;
  }
}
@media screen and (min-width: 640px) {
  .colInfo {
    font-size: calc(12px + 6 * ((100vw - 320px) / 680));
    padding-top: 3px;
  }
  .colInfoModal {
    font-size: calc(8px + 6 * ((100vw - 320px) / 680));
    padding-top: 3px;
  }
  .rowInfo, .rowInfoDisabled{
    font-size: calc(12px + 6 * ((100vw - 320px) / 680));
  }
  .rowInfoModal, .rowInfoDisabledModal {
    font-size: calc(8px + 6 * ((100vw - 320px) / 680));
  }
  .numbers {
    font-size: calc(30px + 6 * ((100vw - 320px) / 1080));
  }
  .numbersModal {
    font-size: calc(20px + 6 * ((100vw - 320px) / 1080));
  }
  .trendIcon {
    width: 48px;
    height: 48px;
  }
  .trendIconModal {
    width: 28px;
    height: 28px;
  }
}
@media screen and (min-width: 1080px) {
  .rowInfo, .rowInfoDisabled {
    font-size: calc(22px + 6 * ((100vw - 320px) / 1080));
  }
  .rowInfoModal, .rowInfoDisabledModal {
    font-size: calc(16px + 6 * ((100vw - 320px) / 1080));
  }
  .numbers {
    font-size: calc(50px + 6 * ((100vw - 320px) / 1080));
  }
  .numbersModal {
    font-size: calc(30px + 6 * ((100vw - 320px) / 1080));
  }
  .trendUpALot .trendUp .trendDown .trendDownUpALot{
    font-size: calc(20px + 6 * ((100vw - 320px) / 1080));
  }
  .trendUpALotModal .trendUpModal .trendDownModal .trendDownUpALotModal {
    font-size: calc(20px + 6 * ((100vw - 320px) / 1080));
  }
  .trendIcon {
    width: 60px;
    height: 60px;
  }
  .trendIconModal {
    width: 40px;
    height: 40px;
  }
}
.numbers, .numbersModal {
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.table {
  font-size: 1.2rem;
  width: 85%;
  margin: 2% auto;
}

.messaging-reach-table {
  font-size: 1.2rem;
  width: 100%;
  position: relative;
  padding: 5px 0;
  overflow-x: visible !important;
}
.messaging-reach-table::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 5px;
  background-color: #6e7c8b;
  border-radius: 10px;
}

.messaging-reach-table::after {
  margin-top: .5em;
  bottom: 0;
}

.tableModal {
  font-size: 1.2rem;
  width: 100%;
  margin: 2% auto;
}
table, tr, td, .messaging-reach-table td, tr {
  border: 0;
}
tr.noBorder td {
  border: 0;
}
.table thead th, .tableModal thead th, .messaging-reach-table thead th {
    vertical-align: bottom;
    border: 0;
}
table th, .table td, .tableModal td {
    padding: 0.75rem;
    vertical-align: middle;
    border: 0;
}

.numbers-messaging {
  font-size: calc(20px + 6 * ((100vw - 20%) / 1080));
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}

.trend-contents{
  font-size: calc(10px + 3 * ((100vw - 20%) / 1080));
}
.upward-trend-content {
  color: #08AD0A;
}

.flat-trend-content {
  color: blue;
}

.downward-trend-content {
  color: red;
}

.disabled-trend-content {
  color: gray;
}

.disabled-trend-letters {
  color: gray;
}

.row-messaging {
  display: flex;
  flex-direction: row;
  align-items: center;
}

  .row-messaging {
    display: inline-flex;
    text-align: end;
    flex-direction: column;
  }
  .left-half {
    flex: 0;
    text-align: right;
  }
  .right-half {
    flex: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.row-header-messaging-reach{
  text-align: left;
  text-wrap: nowrap;
}

.overflow-col{
  overflow-x: scroll;
}