@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";

.message-label {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
    h6 {
      color: $darkTextColor;
      margin: 0;
      margin-right: $minMargin * 2;
      font: bold 22px/25px $defaultFontFamily;
    }
  }

.org-settings-card {
  background: #f8f8f8;
  margin: 1em 0;
}

.col-top {
  background: #f8f8f8;
  margin-bottom: 1em;
}

.org-settings-card-full {
  background: #f8f8f8;
  text-align: center;
  height: calc(100% - 1em);
}

.org-settings-row {
  display: flex;
  text-align: center;
  flex-flow: row wrap;
  justify-content: space-between;

}

.align-row-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: .5em 0px 0px 0px
}

.modal-full-large{
  min-width: 1800px !important;
}

.xxl-container {
  max-width: 100% !important;
}

.row-container-align-right{
  justify-content: flex-end;
  width: 100%;
}
