@import "~assets/scss/variables.scss";

/* Customize the label (the container) */
.root {

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;

  cursor: pointer;
  // font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $lightBackground;
    border: 1px solid $defulaHeaderColor;
    border-radius: $smallerRadius;
    margin-right: 1em;

  }
  
  /* When the checkbox is checked, add a primaryColor background */
  input:checked ~ .checkmark {
    background-color: $primaryColor ;
  }
  
  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkmark:after {
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}