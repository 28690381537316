@import "~assets/scss/variables.scss";

.root {
  border-bottom-left-radius: $bigRadius;
  border-bottom-right-radius: $bigRadius;
  background-color: $darkBackground;
  padding: 22.12px 40px;
  z-index: 0;
  position: relative;

  img.faq-img:hover {
    cursor: pointer;
  }
}

.footer-text {
  color: $footerTextColor;
  text-align: center;
  font-size: 20px;
}
