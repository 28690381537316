@import "~assets/scss/variables.scss";
@import "~assets/scss/variables.scss";
@import "~assets/scss/pages.scss";

.horizontal, .week-days-wrapper {
  display: flex;
  flex-direction: wrap;
  width: 75%;
  align-items: center;
  margin-bottom: 16px;
}
.showAnalyticsBtn {
  display: flex;
  justify-content: center;
  margin-bottom: $minMargin * 2;
}

.nudgeAssistBtn {
  display: flex;
  justify-content: center;
  margin-bottom: $minMargin * 2;
}

.half-width {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.week-days-wrapper {
  align-items: center;
  
  div {
    margin-top: 0;
  }

  .label {
    margin-top: 0;
    min-width: 150px;
    margin-left: 1px;
  }
}

.container {
    display: flex;
    justify-content: flex-start; /* Align items to the start */
    padding: -1px 10px; /* Add padding if needed */
    width: 100%; /* Ensure the container takes full width */
}

/* Wrapper for checkbox and label */
.checkboxWrapper {
    display: flex;
    align-items: center; /* Center items vertically */
    flex-wrap: nowrap; /* Prevent items from wrapping */
    max-width: 100%; /* Ensure it does not exceed container width */
}

/* Style for checkbox label */
.checkboxLabel {
    color: #2FBFFE;
    padding-bottom: 8px;
    white-space: nowrap; /* Prevents text from wrapping */
    margin-left: 8px; /* Space between checkbox and text */
}

/* Container for time label and time zone */
.timeLabelContainer {
    display: flex;
    align-items: center; /* Vertically center items */
    width: 100%; /* Ensure it takes up full width of the parent */
}

.customInput {
    margin-right: 8px; /* Space between dropdowns */
}

/* Style for the time label and inputs */
.timeLabel {
    display: flex;
    align-items: center; /* Vertically align inputs */
    margin-right: 16px; /* Space between time inputs and time zone */
    min-width: 0;
}

/* Style for the time zone text */
.timeZone {
    padding-top: 2px;
    margin-left: 16px; /* Space between inputs and time zone */
    white-space: nowrap; /* Prevent text from wrapping */
}

.label {
  color: $primaryColor;
  font: bold 22px/23px $defaultFontFamily;
  margin-left: $minMargin * 2;
  min-width: 150px;
}

.alert {
  font-family: $defaultFontFamily;
  color: red;
  font-size: 1rem;
}

.time-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h6 {
    color: $darkTextColor;
    margin: 0;
    margin-right: $minMargin * 2;
    font: bold 22px/25px $defaultFontFamily;
  }
}

.floating {
  display: none;
  margin: $minMargin * 2;
  justify-content: center;
  align-items: center;
  //min-width: 428px;
  max-height: 90%;

  .custom-card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 428px;
    bottom: 15vh;
    position: sticky;
  }
}

.form-question-header {
    font-weight: bold;
    color: $primaryColor
}

.form-question-header-with-margin {
    font-weight: bold;
    color: $primaryColor;
    margin-top: 1em;
}

.overlay {
    position: fixed; /* Overlay should cover the entire viewport */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
    z-index: 999; /* Ensure overlay is on top of the form */
    pointer-events: auto; /* Make sure the overlay blocks interactions */
}



.messageContainer {
    text-align: center; /* Center the text */
}

.message {
    font-size: 1.5rem; /* Adjust font size as needed */
    color: #333; /* Adjust text color */
    font-weight: bold; /* Optional: make the text bold */
}

.disabledForm {
    pointer-events: none; /* Disable interactions with the form */
    opacity: 0.5; /* Optional: make the form appear disabled */
}

.disable-type-button {
  opacity: 0.6;
  cursor: not-allowed;
}

.disabled-button {
  opacity: 0.6;
  cursor: not-allowed;
}

@media only screen and (min-width: 525px) {
  .floating {
    display: flex;
  }

  .container {
        justify-content: flex-start; /* Align to the start on larger screens */
    }

}

@media only screen and (max-width: 768px) {
  .pl {
    padding-left: 30px !important
  }

  .container {
        justify-content: flex-start; /* Center content on small screens */
    }
   .horizontal {
        flex-direction: column; /* Stack items vertically on small screens */
    }
    .timeLabelContainer {
        flex-direction: column; /* Stack items vertically on small screens */
    }
}

.analyticsButton {
  //display: block;
  //margin-left: auto;
  //margin-right: 0;
  float: right;
    margin-left: -50%;
    margin-top: 2em;
}

@media only screen and (min-width: 1200px) {
  .modal-xl {
    max-width: 1400px !important;
  }
}

.center-button-row{
  display: flex;
  justify-content: center;
  margin-bottom: 2.5em;
}

 //To rearrange order of the elements
.nudge-component {
  display: flex;
}

.nudge-component-1 {
  order: 1; /* Default order */
}

.nudge-component-2 {
  order: 2; /* Default order */
}

/* Media Query: Change the order for screen sizes smaller than 768px */
@media (max-width: $lgScreenSize) {
  nudge-component {
    flex-direction: column;
  }

  .nudge-component-1 {
    order: 2;
  }

  .nudge-component-2 {
    order: 1;
  }
}
